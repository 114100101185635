@import '~in-context';

@keyframes rotate {
	from {
		transform: translate(0, 15%) rotate(0deg) translate(0, 15%); }

	to {
		transform: translate(0, 15%) rotate(-360deg) translate(0, 15%); } }

.App {
	// text-align: center

	@include in-context (small only) {
		margin: 0 1rem; }
	@include in-context(medium) {
		margin: 0 2rem; }
	@include in-context(large) {
		max-width: 36rem;
		margin: 0 auto; } }

h1, h2, h3 {
	text-align: center; }

a, a:visited {
	color: blue; }

.svg-art {
	animation: rotate 5800ms infinite linear;

	svg {
		width: 10rem;
		height: auto;
		color: blue; } }
